    <template>
    <div>
        <v-toolbar flat>
        <v-icon left>mdi-account-box-outline</v-icon>
        <v-toolbar-title>FUENTES DE LEADS</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="abrirDataSourceDialog(null)" color="blue" small dark
            ><v-icon>mdi-plus</v-icon> Crear</v-btn
        >
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="borrarFuente()" color="blue" small dark
            ><v-icon>mdi-delete</v-icon>Borrar</v-btn
        >
        </v-toolbar>
        <v-data-table
        :headers="headers"
        :items-per-page="itemsPerPage"
        :items="fuentes"
        class="elevation-1"
        small
        
        
        item-key="data_source_id"
        hide-default-footer
        >
        <template v-slot:item.opciones="{ item }">
        <v-btn small text @click="borrarFuente(item.data_source_id)" color="blue" small dark
            ><v-icon small color="red">mdi-delete</v-icon></v-btn
        >
        </template>
        <template v-slot:item.data_source_id="{ item }">
            <v-btn text small @click="abrirDataSourceDialog(item.data_source_id)">
            {{ item.data_source_id }}
            </v-btn>
        </template>
        <template v-slot:footer>        
            <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
            ></v-pagination>
        </template>
        </v-data-table>

        <!-- DIALOGO FUENTE -->
        <v-dialog v-model="dialogoDataSource" persistent width="700">
        <v-form ref="formDataSource">
        <v-card>

            <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-account-box-outline</v-icon>
            Fuente de lead
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoDataSource = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
                <v-text-field
                :rules="requiredRule"
                v-model="datosFuente.data_source_id"
                label="Nombre"
                ></v-text-field>
            </v-col>

            <v-col md="12" class="py-0 my-0">
                <v-text-field
                :rules="requiredRule"
                v-model="datosFuente.description"
                label="Descripción"
                ></v-text-field>
            </v-col>
            </v-row>

            <v-toolbar>
            <v-btn color="primary" small @click="guardarFuente">
                <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogoDataSource = false">
                <v-icon>mdi-content-cancel</v-icon>Cancelar
            </v-btn>
            </v-toolbar>
        </v-card>
        </v-form>
        </v-dialog>
    </div>
    </template>

    <script>
    import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
    import Vue from 'vue';

    export default {
    name: "FuentesLeads",
    data: () => ({
        show: false,

        requiredRule: [(v) => !!v || "El campo es requerido"],
        options: {},
        setfilters: [],
        selected: [],
        users: [],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 10,
        pageCount: 0,
        required: [(v) => !!v || "El campo es requerido"],
        headers: [
            { text: "Opciones", align: "start", value: "opciones" },
            { text: "ID", align: "start", value: "data_source_id" },
            { text: "Tipo", align: "start", value: "data_source_type_id" },
            { text: "Nombre", align: "start", value: "description" },
        ],
        fuentes: [],
        selFuentes: [],
        dialogoDataSource: false,
        datosFuente: {
            data_source_id: "",
            data_source_type_id: "",
            description: "",
        },
        crearFuente: false,
    }),
    computed: {
        ...mapState("master", ["loadingBtn", "companies"]),
        ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

        ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
        ...mapMutations("master", [
        "setUrl",
        "setLoadingTable",
        "setTitleToolbar",
        "setTenantId",
        ]),

        ...mapActions("master", ["requestApi", "alertNotification"]),
        ...mapActions("access", []),

        cargarDatos() {
        this.setLoadingTable(true);

        this.setUrl("data-source");

        this.requestApi({
            method: "GET",
            data: {
            page: this.currentPage,
            page_size: this.itemsPerPage,
            paginator: true,
            source_type_id: "LEAD_SOURCE",
            },
        })
            .then((res) => {
            //console.log(res.data);
            this.fuentes = res.data._embedded.data_source;
            this.pageCount = res.data.page_count;
            this.currentPage = res.data.page;
            this.totalItems = res.data.total_items;
            })
            .then(() => {
            this.setLoadingTable(false);
            });
        },
        abrirDataSourceDialog(dsId) {
        this.dialogoDataSource = true;
        this.datosFuente = {
            data_source_id: "",
            data_source_type_id: "",
            description: "",
        };
        this.crearFuente = true;
        if (dsId != null) {
            this.crearFuente = false;
            this.cargarDatosDataSource(dsId);
        }
        },

        cargarDatosDataSource(id) {
        this.setLoadingTable(true);
        this.setUrl("data-source/" + id);
        this.requestApi({
            method: "GET",
            data: {},
        })
            .then((res) => {
            console.log(res.data);
            this.datosFuente = res.data;
            })
            .then(() => {
            this.setLoadingTable(false);
            });
        },

        guardarFuente() {
        if(!this.$refs.formDataSource.validate()) {
            return false
        }
            this.setLoadingTable(true);
            this.setUrl("data-source");
            this.requestApi({
            method: "POST",
            data: {
                accion: this.crearFuente == true ? "crearFuente" : "modificarFuente",
                data_source: this.datosFuente,
            },
            })
            .then((res) => {
                console.log(res.data);
                this.cargarDatos();
                this.dialogoDataSource = false;
            })
            .then(() => {
                this.setLoadingTable(false);
            });
        
        },
        borrarFuente(dsId) {

        Vue.swal({
                    html: "Está seguro de eliminar esta fuente ?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then( result => {

        this.setLoadingTable(true);
        this.setUrl("data-source");
        this.requestApi({
            method: "DELETE",
            data: {
            accion: "borrarFuente",
            data_source_id: dsId, // this.selFuentes[0].data_source_id,
            },
        })
            .then((res) => {
            //console.log(res.data);
            this.cargarDatos();
            this.dialogoDataSource = false;
            })
            .then(() => {
            this.setLoadingTable(false);
            });
        });
        },
        handlePageChange(value) {
        this.cargarDatos();
        },
    },
    mounted() {
        this.cargarDatos();
        this.setTitleToolbar("FUENTES DE LEADS");
    },
    };
    </script>
